import { useRect, useDebug } from '@studio-freight/hamo'
import cn from 'clsx'
import 'lib/i18n'

import { AJunkWechat } from 'components/a-junk-wechat'
import { StayTuned } from 'components/stay-tuned'
import { StayView } from 'components/stay-view'

import { Button } from 'components/button'
import { Card } from 'components/card'
import { Title } from 'components/intro'
import { Link } from 'components/link'
import { ListItem } from 'components/list-item'
import { RealViewport } from 'components/real-viewport'

import { Parallax } from 'components/parallax'
import { AppearTitle } from 'components/appear-title'
import { HorizontalSlides } from 'components/horizontal-slides'
import { FeatureCards } from 'components/feature-cards'
import { WebGL } from 'components/webgl'
import { FloatController } from 'components/float-controller'
import { Adchat } from 'components/adchat'
import {UserList} from 'components/user-list'

import { useScroll } from 'hooks/use-scroll'
import { Layout } from 'layouts/default'
import { button, Leva, useControls } from 'leva'
import { clamp, mapRange } from 'lib/maths'
import { useStore } from 'lib/store'
import { useEffect, useRef, useState } from 'react'
import { useIntersection, useWindowSize } from 'react-use'
import s from './app.module.scss'
import 'styles/global.scss'
import { ReactComponent as DmGang } from 'icons/dmgang.svg'
import { ReactComponent as GitHub } from 'icons/github.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { raf } from '@studio-freight/tempus'

import { useTranslation, Trans } from "react-i18next";

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.defaults({ markers: process.env.NODE_ENV === 'development' })

  // merge rafs
  gsap.ticker.lagSmoothing(0)
  gsap.ticker.remove(gsap.updateRoot)
  raf.add((time) => {
    gsap.updateRoot(time / 1000)
  }, 0)
}

const HeroTextIn = ({ children, introOut }) => {
  return (
    <div className={cn(s['hide-text'], introOut && s['show-text'])}>
      {children}
    </div>
  )
}

export default function App() {
  const [hasScrolled, setHasScrolled] = useState()
  const zoomRef = useRef(null)
  const [zoomWrapperRectRef, zoomWrapperRect] = useRect()
  const { height: windowHeight } = useWindowSize()
  const introOut = useStore(({ introOut }) => introOut)

  const [theme, setTheme] = useState('dark')
  const lenis = useStore(({ lenis }) => lenis)

  useScroll(ScrollTrigger.update)

  useEffect(() => {
    if (lenis) {
      ScrollTrigger.refresh()
      lenis?.start()
    }
  }, [lenis])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  ScrollTrigger.defaults({ markers: process.env.NODE_ENV === 'development' })

  const dialogRef = useRef(null)
  const setIsShow = () => {
    dialogRef.current.setIsShow(true);
  }

  const dialogView = useRef(null)
  const { i18n } = useTranslation()
  const setIsView = () => {
    debugger
    const lng = i18n.language
    if(lng === 'en-US') {
      window.open('https://promptcoin.gitbook.io/promptcoin-whitepaper-v2.2-en/')
    } else if (lng === 'zh-CN') {
      window.open('https://promptcoin.gitbook.io/promptcoin-whitepaper-v2.2-cn/')
    } else {
      window.open('https://promptcoin.gitbook.io/promptcoin-whitepaper-v2.2-jp/')
    }
  }

  useControls(
    'animation',
    () => ({
      stop: button(() => {
        lenis.stop()
      }),
      start: button(() => {
        lenis.start()
      }),
    }),
    [lenis]
  )

  useControls(
    'scrollTo',
    () => ({
      immediate: button(() => {
        lenis.scrollTo(30000, { immediate: true })
      }),
      smoothDuration: button(() => {
        lenis.scrollTo(30000, { lock: true, duration: 10 })
      }),
      smooth: button(() => {
        lenis.scrollTo(30000)
      }),
      forceScrollTo: button(() => {
        lenis.scrollTo(30000, { force: true })
      }),
    }),
    [lenis]
  )

  useScroll(({ scroll }) => {
    setHasScrolled(scroll > 10)
    if (!zoomWrapperRect.top) return
    const start = zoomWrapperRect.top + windowHeight * 0.5
    const end = zoomWrapperRect.top + zoomWrapperRect.height - windowHeight

    const progress = clamp(0, mapRange(start, end, scroll, 0, 1), 1)
    const center = 0.6
    const progress1 = clamp(0, mapRange(0, center, progress, 0, 1), 1)
    const progress2 = clamp(0, mapRange(center - 0.055, 1, progress, 0, 1), 1)
    setTheme(progress2 === 1 ? 'light' : 'dark')

    zoomRef.current.style.setProperty('--progress1', progress1)
    zoomRef.current.style.setProperty('--progress2', progress2)

    if (progress === 1) {
      zoomRef.current.style.setProperty('background-color', 'currentColor')
    } else {
      zoomRef.current.style.removeProperty('background-color')
    }
  })

  const [whyRectRef, whyRect] = useRect()
  const [cardsRectRef, cardsRect] = useRect()
  const [whiteRectRef, whiteRect] = useRect()
  const [featuresRectRef, featuresRect] = useRect()
  const [inuseRectRef, inuseRect] = useRect()

  const addThreshold = useStore(({ addThreshold }) => addThreshold)

  useEffect(() => {
    addThreshold({ id: 'top', value: 0 })
  }, [])

  useEffect(() => {
    const top = whyRect.top - windowHeight / 2
    addThreshold({ id: 'why-start', value: top })
    addThreshold({
      id: 'why-end',
      value: top + whyRect.height,
    })
  }, [whyRect])

  useEffect(() => {
    const top = cardsRect.top - windowHeight / 2
    addThreshold({ id: 'cards-start', value: top })
    addThreshold({ id: 'cards-end', value: top + cardsRect.height })
    addThreshold({
      id: 'red-end',
      value: top + cardsRect.height + windowHeight,
    })
  }, [cardsRect])

  useEffect(() => {
    const top = whiteRect.top - windowHeight
    addThreshold({ id: 'light-start', value: top })
  }, [whiteRect])

  useEffect(() => {
    const top = featuresRect.top
    addThreshold({ id: 'features', value: top })
  }, [featuresRect])

  useEffect(() => {
    const top = inuseRect.top
    addThreshold({ id: 'in-use', value: top })
  }, [inuseRect])

  useEffect(() => {
    const top = lenis?.limit
    addThreshold({ id: 'end', value: top })
  }, [lenis?.limit])

  const inUseRef = useRef()

  const [visible, setIsVisible] = useState(false)
  const intersection = useIntersection(inUseRef, {
    threshold: 0.2,
  })
  useEffect(() => {
    if (intersection?.isIntersecting) {
      setIsVisible(true)
    }
  }, [intersection])

  const { t } = useTranslation();
  const roles = [
    { title: t('project coordinator'),text:t('coordinator'), source: 'Dennis Kok', national:'(Japan)'},
    { title: t('head of market pr capital management'), text:t('management'),source: 'Jason Hu', national:'(USA)'},
    { title: t('ribenip'),text: t('ribenipjy'), source: t('ribenip name'),national:'(Japan)' },
    { title: t('cto'),text:t('cto content'),source: 'Hoshino Kimi',national:'(Japan)' },
    { title: t('blockchain developer'),text: t('developer'), source: 'Albert Yang',national:'(Japan)' },
    { title: t('technical director'), text:t('director'), source: 'Jack Z',national:'(Japan)' },
  ]

  const users = [
        {avatar:'/KOL/01.png',href:'https://www.tiktok.com/@moena315'},
        {avatar:'/KOL/02.png',href:'https://www.tiktok.com/@minnakowaikarayada7'},
        {avatar:'/KOL/03.png',href:'https://www.tiktok.com/@maydei.3010'},
        {avatar:'/KOL/4.png',href:'https://www.douyin.com/user/MS4wLjABAAAAfcVRrExpSb80InjugAtdjrvzABpfmXzxhmVxK21on7s'},
        {avatar:'/KOL/04.png',href:'https://www.tiktok.com/@muwe.c?lang=ja-JP'},
        {avatar:'/KOL/05.png',href:'https://www.tiktok.com/@sleepyboyngirl?lang=ja-JP'},
        {avatar:'/KOL/06.png',href:'https://www.tiktok.com/@esssterka.m'},
        {avatar:'/KOL/07.png',href:'https://www.tiktok.com/@yu.bro'},
        {avatar:'/KOL/08.png',href:'https://www.tiktok.com/@imjoshfromengland2'},
        {avatar:'/KOL/09.png',href:'https://www.tiktok.com/@americagottalent86'},
        {avatar:'/KOL/10.png',href:'https://www.tiktok.com/@paquideus'},
        {avatar:'/KOL/12.png',href:'https://www.douyin.com/user/MS4wLjABAAAAabK8PmRs73JEy9HtTtlCwEcisQwtHTytkqOwwOOJ8VU'},
        {avatar:'/KOL/11.png',href:'https://www.tiktok.com/@anankokoko'},
        {avatar:'/KOL/12.png',href:'https://www.tiktok.com/@kojorin'},
        {avatar:'/KOL/13.png',href:'https://www.tiktok.com/@kano193com'},
        {avatar:'/KOL/14.png',href:'https://www.tiktok.com/@banchoes'},
        {avatar:'/KOL/15.png',href:'https://www.tiktok.com/@tokihirosabu'},
        {avatar:'/KOL/16.png',href:'https://www.tiktok.com/@nagi.mugi'},
        {avatar:'/KOL/17.png',href:'https://www.tiktok.com/@kimiire'},
        {avatar:'/KOL/18.png',href:'https://www.tiktok.com/@sacla075'},
        {avatar:'/KOL/19.png',href:'https://www.tiktok.com/@na7ashi_'},
        {avatar:'/KOL/20.png',href:'https://www.tiktok.com/@hoshino0510'},
        {avatar:'/KOL/21.png',href:'https://www.tiktok.com/@uespiiiii.1115'},
        {avatar:'/KOL/33.png',href:'https://www.douyin.com/user/MS4wLjABAAAAZH82wF8D8aG7MqHKa8HAiZrpe5WLa1zZdLro3Q2s33OzFjV5mGZrYLaVE7V2Y-ki'},
      ]
  const ins = [
    {avatar:'/ins/1.png',href:'https://www.jmode.co.jp/'},
    {avatar:'/ins/3.png',href:'http://www.iesolutions.jp/'},
    {avatar:'/ins/5.png',href:'https://www.fullpay.com/'},
    {avatar:'/ins/7.png',href:'https://www.lorealparisjapan.jp/'},
    {avatar:'/ins/9.png',href:'https://www.rakuten.co.jp/'},
    {avatar:'/ins/11.png',href:'https://www.lotte.co.jp/'},
    {avatar:'/ins/2.png',href:'https://www.itoen.co.jp/'},
    {avatar:'/ins/4.png',href:'https://www.suntory.co.jp/'},
    {avatar:'/ins/6.png',href:'https://www.sawaicoffee.co.jp/'},
    {avatar:'/ins/8.png',href:'https://helloboss.com/'},
    {avatar:'/ins/10.png',href:'https://pet.nga-x.com/'},
    {avatar:'/ins/12.png',href:'https://www.y-aoyama.jp/'},
  ]

  const ins2 = [
    {avatar:'/ins/18.png',href:'https://www.cgv.fund/'},
    {avatar:'/ins/15.png',href:'https://www.teamz.co.jp/'},
    {avatar:'/ins/16.png',href:'https://confluxnetwork.org/'},
    {avatar:'/ins/19.png',href:'https://metafocus.nl/'},
    {avatar:'/ins/21.png',href:'https://www.chaincatcher.com/'},
    {avatar:'/ins/23.png',href:'https://www.sbinvestment.co.jp/'},
    {avatar:'/ins/14.png',href:'https://www.avatardao.me/'},
    {avatar:'/ins/13.png',href:'https://www.k24.fund/'},
    {avatar:'/ins/20.png',href:'http://www.web3vision.com/'},
  ]

  const chunk = (arr, size) => {
    const chunked_arr = []
    let index = 0
    while (index < arr.length) {
      chunked_arr.push(arr.slice(index, size + index))
      index += size
    }
    return chunked_arr
  }

  const list = chunk(users, 6)

  const insList = chunk(ins, 6)

  const insList2 = chunk(ins2,5)

  const debug = useDebug();

  const openUrl = () => {
    window.open('https://ai.promptcoin.me/')
  }
  return (
    <Layout
      theme={theme}
      seo={{
        title: 'DMANG - DECENTRALIZED MARKETING GANG',
        description:
          'Promptcoin never plays by the rules of logic. We recruit the most ruthless members to our GANG, and you need to bring your own subversive genes, because the world has already been disrupted by AI.',
      }}
      className={s.home}
    >
      <Leva hidden={!debug} />
      <StayTuned cRef={dialogRef}/>
      <StayView cRef={dialogView}/>
      {/* <AJunkWechat /> */}
      <FloatController />
      <RealViewport />
      <div className={s.canvas}>
        <WebGL />
      </div>

      <section className={s.hero}>
        <div className="layout-grid-inner">
          <Title className={s.title} />
          {/* <DmGang className={cn(s.icon, introOut && s.show)} /> */}
          <span className={cn(s.sub)}>
            <HeroTextIn introOut={introOut}>
              <h2 className={cn('h3', s.subtitle)}>prompt to earn</h2>
            </HeroTextIn>
            <HeroTextIn introOut={introOut}>
              <h2 className={cn('p-xs', s.tm)}>
                <span>©</span> {new Date().getFullYear()} prompt to earn
              </h2>
            </HeroTextIn>
          </span>
        </div>

        <div className={cn(s.bottom, 'layout-grid')}>
          <div
            className={cn(
              'hide-on-mobile',
              s['scroll-hint'],
              hasScrolled && s.hide,
              introOut && s.show
            )}
          >
            <div className={s.text}>
              <HeroTextIn introOut={introOut}>
                <p>{t('scroll')}</p>
              </HeroTextIn>
              <HeroTextIn introOut={introOut}>
                <p>{t('to explore')}</p>
              </HeroTextIn>
            </div>
          </div>
          <h1 className={cn(s.description, 'p-xs')}>
            <HeroTextIn introOut={introOut}>
              <p className="p-xs">{t('dmgang never text 1')}</p>
            </HeroTextIn>
            <HeroTextIn introOut={introOut}>
              <p className="p-xs">{t('dmgang never text 2')}</p>
            </HeroTextIn>
          </h1>
          <h1 className={s.ceshi} onClick={openUrl}>
            <HeroTextIn introOut={introOut}>
              <p className="p-xs">{t('test')}</p>
            </HeroTextIn>
            </h1>         
          <Button
            className={cn(s.cta, introOut && s.in)}
            arrow
            icon={<GitHub />}
            onClick={setIsShow}
          >
            MINT NFT
          </Button>
        </div>
      </section>
      <div id='overview' />
      <section className={s.why} data-lenis-scroll-snap-align="start">
        <div className="layout-grid">
          <h2 className={cn(s.sticky, 'h3',s.overTitle)}>
            <AppearTitle>{t('dmgang overview')}</AppearTitle>
          </h2>
          <aside className={s.features} ref={whyRectRef}>
            <div className={s.feature}>
              <h3 className={cn(s.title, 'h4')}>
                {t('dmgang story')}
              </h3>
              <p className={`p ${s.preline}`}>
                {t('dmgang story content')}
              </p>
            </div>
            <div className={s.feature}>
              <h3 className={cn(s.title, 'h4')}>
                {t('introduction')}
              </h3>
              <p className={`p ${s.preline}`}>
                {t('introduction content')}
              </p>
            </div>
            <div className={s.feature}>
              <h3 className={cn(s.title, 'h4')}>
                {t('first city')}
              </h3>
              <p className={`p ${s.preline}`}>
                {t('first city content')}
              </p>
            </div>
          </aside>
        </div>
      </section>
      <div id='nft-introduction' />
      <section className={s.rethink}>
        <div className={cn('layout-grid', s.pre)}>
          <div className={s.highlight} data-lenis-scroll-snap-align="start">
            <Parallax speed={-0.5}>
              <p className={`h3 ${s.introtitle}`}>
                <AppearTitle>{t('nft introduction')}</AppearTitle>
              </p>
            </Parallax>
          </div>
          <div className={s.comparison}>
            <Parallax speed={0.5}>
              <p className={`p ${s.preline}`}>
                <Trans i18nKey="nft introduction content">
                  nft introduction content <span className="contrast semi-bold">three-level</span> structure. <br /> <br />
                  text1 <br /> <br />
                  text2 <span className="contrast semi-bold">community of interests</span> text3
                </Trans>
              </p>
            </Parallax>
          </div>
        </div>
        <div className={s.cards} ref={cardsRectRef}>
        <h2 className={cn(s.sticky, s.sticTitle,'h3')}>
            <AppearTitle>{t('backed')}</AppearTitle>
            <div className={cn(s.outList,s.insItem)}>
              {insList2.map((item, i) => {
                return (
                  <div className={s.listBox2} key={i}>
                    <UserList users={item}></UserList>
                  </div>
                )
              })}
            </div>
          </h2>
        <h2 className={cn(s.sticky, 'h3')}>
            <AppearTitle>{t('trial')}</AppearTitle>
          </h2>
          <HorizontalSlides>
            {/* <div style={{display:'flex'}}> */}
            <p className={s.userTitle}>{t('Institutions')}</p>
            <div className={s.outList}>
              {insList.map((item, i) => {
                return (
                  <div className={s.listBox} key={i}>
                    <UserList users={item}></UserList>
                  </div>
                )
              })}
              <img className={s.carImg} src={process.env.PUBLIC_URL + '/car.png'} alt="" />
              </div>
            <p className={s.userTitle}>KOLs</p>
            <div className={s.outList}>
              {list.map((item, i) => {
                return (
                  <div className={s.listBox} key={i}>
                    <UserList users={item}></UserList>
                  </div>
                )
              })}
            </div>

          {/*  <Card*/}
          {/*    className={s.card}*/}
          {/*    number={t('design01')}*/}
          {/*    title="DM GANG generation I"*/}
          {/*    text={t('design01 content')}*/}
          {/*  />*/}
          {/*  <Card*/}
          {/*    className={s.card}*/}
          {/*    number={t('design02')}*/}
          {/*    title="DM GANG generation II"*/}
          {/*    text={t('design02 content')}*/}
          {/*  />*/}
          {/*  <Card*/}
          {/*    className={s.card}*/}
          {/*    number={t('design03')}*/}
          {/*    title="DM GANG generation III"*/}
          {/*    text={t('design03 content')}*/}
          {/*  />*/}
          {/*  <Card*/}
          {/*    className={s.card}*/}
          {/*    number={t('token mechanism')}*/}
          {/*    text={t('token mechanism content')}*/}
          {/*  />*/}
          {/*  <Card*/}
          {/*    className={s.card}*/}
          {/*    number={t('earning model')}*/}
          {/*    text={t('earning model content')}*/}
          {/*  />*/}
          </HorizontalSlides>
            <h2 className={cn(s.stickyImg, 'h3')}>
            <AppearTitle>Celebrities</AppearTitle>
              <img className={cn(s.starsImg,'hide-on-mobile')} src={process.env.PUBLIC_URL + '/star.png'} alt="" />
              <img className={cn(s.starsImg,'hide-on-desktop')} src={process.env.PUBLIC_URL + '/star_mobile.png'} alt="" />
          </h2>  
        </div>
      </section>
      <div id='doc' />
      <section
        ref={(node) => {
          zoomWrapperRectRef(node)
          zoomRef.current = node
        }}
        className={s.solution}
      >
        <div className={s.inner}>
          <div className={s.zoom}>
            <h2 className={cn(s.first, 'h1 vh')}>
              <Trans i18nKey="view white paper">
                VIEW <br />
                <Link className={`${s.link} myConstrast`} onClick={setIsView}>
                  WHITE PAPER.Doc
                </Link>
              </Trans>
            </h2>
            <h2 className={cn(s.enter, 'h3 vh')}>
              SUBVERSIVE <br /> GENES
            </h2>
            <h2 className={cn(s.second, 'h1 vh')}>{t('scrool to explore')}</h2>
          </div>
        </div>
      </section>
      <section className={cn('theme-light', s.featuring)} ref={whiteRectRef}>
        <div className={s.inner}>
          <div className={cn('layout-block', s.intro)}>
            <p className="p-l">
              <Trans i18nKey="adchat info">
                Hi,I am
                <span className="contrast semi-bold">ADChat</span>{', '}
                info
              </Trans>
            </p>
            <Adchat />
          </div>
        </div>
        <div id='roadmap' />
        <section ref={featuresRectRef}>
          <FeatureCards />
        </section>
      </section>
      <div id='team' />
      <section
        ref={(node) => {
          inuseRectRef(node)
          inUseRef.current = node
        }}
        className={cn('theme-light', s['in-use'], visible && s.visible)}
      >
        <div className="layout-grid">
          <aside className={s.title}>
            <p className="h3">
              <AppearTitle>
                <Trans i18nKey="team introduction">
                  TEAM
                  <br />
                  <span className="title-color">INTRODUCTION</span>
                </Trans>
              </AppearTitle>
            </p>
          </aside>
          <ul className={s.list}>
            {roles.map(({ title,text,source,national }, i) => (
              <li key={i}>
                <ListItem
                  title={title}
                  text={text}
                  source={source}
                  national={national}
                  index={i}
                  visible={visible}
                />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}
