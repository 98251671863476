import cn from 'clsx'
import { useState } from 'react'
import s from './float-controller.module.scss'
import { useAudio } from 'hooks/use-audio'
import { ReactComponent as AudioPlay } from 'icons/audio-play.svg'
import { ReactComponent as AudioStop } from 'icons/audio-stop.svg'
import { ReactComponent as ArrowUp } from 'icons/arrow-up.svg'
import { ReactComponent as Menu } from 'icons/menu.svg'
import { Link } from 'components/link'
import { ReactComponent as Twitter } from 'icons/twitter.svg'
import { ReactComponent as I18n } from 'icons/i18n.svg'
import { useTranslation } from "react-i18next";

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/animations/perspective.css'

export const FloatController = () => {
  const [playing, setPlaying] = useState(false);
  const { pause, play } = useAudio(process.env.PUBLIC_URL + '/audios/bg-audio.mp3')

  const togglePlaying = () => {
    setPlaying(!playing)
    if (playing) {
      pause()
    } else {
      play()
    }
  };

  const smoothScroll = () => {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (parseInt(currentScroll) > 0) {
        window.requestAnimationFrame(smoothScroll);
    }
    let nextScroll = parseInt(currentScroll - currentScroll / 8);
    if (nextScroll <= 0) {
        nextScroll = 0;
    }
    document.documentElement.scrollTop = nextScroll;
    document.body.scrollTop = nextScroll;
  }

  const toTop = () => {
    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (parseInt(currentScroll) > 0) {
      window.requestAnimationFrame(smoothScroll);
    }
  }

  const { i18n } = useTranslation()
  const changeLanguage = (value) => {
    const lng = i18n.language = value
    i18n.changeLanguage(lng)
  }

  return (
    <button className={s.controller}>
       <Tippy
        placement='right'
        trigger='click'
        theme='light-border'
        animation='perspective'
        interactive={true}
        content={
          <div className={s.links}>
            <Link className={cn(s.link, 'p-xs')} onClick={()=>changeLanguage('en-US')}>
              English
            </Link>
            <Link className={cn(s.link, 'p-xs')} onClick={()=>changeLanguage('zh-CN')}>
              中文
            </Link>
            <Link className={cn(s.link, 'p-xs')} onClick={()=>changeLanguage('ja')}>
              日本語
            </Link>
          </div>
        }
      >
         <I18n className={s.icon} title="language" />
      </Tippy>
      <Tippy
        placement='right'
        trigger='click'
        theme='light-border'
        animation='perspective'
        interactive={true}
        content={
          <div className={s.links}>
            <Link className={cn(s.link, 'p-xs')} href="#overview">
              OVERVIEW
            </Link>
            <Link className={cn(s.link, 'p-xs')} href="#nft-introduction">
              CONTENT PARTNER
            </Link>
            <Link className={cn(s.link, 'p-xs')} href="#doc">
              DOC
            </Link>
            <Link className={cn(s.link, 'p-xs')} href="#roadmap">
              ROADMAP
            </Link>
            <Link className={cn(s.link, 'p-xs')} href="#team">
              TEAM
            </Link>
            <Link className={cn(s.link, 'p-xs')} href="https://twitter.com/promptcoin">
                <Twitter className={s.iicon} />
            </Link>
          </div>
        }
      >
        <Menu id='float-menu' className={s.icon} title="Menu" />
      </Tippy>
      {
        playing
          ? <AudioPlay className={s.icon} onClick={togglePlaying} />
          : <AudioStop className={s.icon} style={{ transform: 'scale(0.82)' }} onClick={togglePlaying} />
      }
      <ArrowUp className={s.icon} onClick={toTop} />
    </button>
  )
}