import { useMediaQuery } from '@studio-freight/hamo'
import cn from 'clsx'
import { useStore } from 'lib/store'
import { useEffect, useState } from 'react'
import s from './intro.module.scss'

export const Intro = () => {
  const isMobile = useMediaQuery('(max-width: 800px)')
  const [isLoaded, setIsLoaded] = useState(false)
  const [scroll, setScroll] = useState(false)
  const introOut = useStore(({ introOut }) => introOut)
  const setIntroOut = useStore(({ setIntroOut }) => setIntroOut)
  const lenis = useStore(({ lenis }) => lenis)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 1000)
  }, [])

  useEffect(() => {
    if (isMobile) {
      document.documentElement.classList.toggle('intro', false)
      return
    }

    if (!scroll) {
      document.documentElement.classList.toggle('intro', true)
    }

    if (lenis) {
      if (scroll) {
        lenis.start()
        document.documentElement.classList.toggle('intro', false)
      } else {
        // setTimeout(() => {
        //   lenis.stop()
        // }, 0)

        document.documentElement.classList.toggle('intro', true)
      }
    }
  }, [scroll, lenis, isMobile])

  return (
    <div
      className={cn(s.wrapper, isLoaded && s.out)}
      onTransitionEnd={(e) => {
        e.target.classList.forEach((value) => {
          if (value.includes('out')) {
            setScroll(true)
          }
          if (value.includes('show')) {
            setIntroOut(true)
          }
        })
      }}
    >
      <div className={cn(isLoaded && s.relative)}>
        <DM isLoaded={isLoaded} fill={'var(--blue)'} />
        <DANG
          isLoaded={isLoaded}
          fill={'var(--red)'}
          className={cn(introOut && s.translate)}
        />
      </div>
    </div>
  )
}

export const Title = ({ className }) => {
  const introOut = useStore(({ introOut }) => introOut)

  return (
    <div className={className}>
      <DM fill={'var(--blue)'} />
      <DANG
        fill={'var(--red)'}
        className={cn(introOut && s.translate, s.mobile)}
      />
    </div>
  )
}

const DM = ({ isLoaded, className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1850 317"
      className={cn(s.lns, className)}
    >
      <g fill={fill}>
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M0 4.38957H65.7513V331.207H0V4.38957Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M87.3604 181.494H70V133.925H83.1838C90.8409 133.925 96.6417 132.403 100.586 129.359C104.531 126.314 107.083 121.747 108.243 115.659C109.636 109.57 110.332 101.832 110.332 92.445C110.332 84.5802 109.752 77.7303 108.591 71.8952C107.663 65.8063 105.227 60.986 101.282 57.4342C97.3378 53.8824 91.1889 52.1064 82.8357 52.1064H70V4.15684H94.6694C111.608 4.15684 125.53 7.70867 136.435 14.8123C147.341 21.6622 155.462 31.8103 160.799 45.2565C166.136 58.449 168.804 74.559 168.804 93.5866C168.804 117.435 165.207 135.701 158.014 148.386C150.821 160.818 141.076 169.443 128.778 174.264C116.48 179.084 102.675 181.494 87.3604 181.494Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M258 4.15684H196.118V312.404H258V4.15684Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M266 174.264H276.169L307.145 312.404H371.534L332.901 163.228C342.182 158.407 349.259 152.445 354.132 145.342C359.237 137.984 362.717 129.359 364.573 119.464C366.43 109.57 367.358 98.2801 367.358 85.595C367.358 64.0304 363.413 47.4129 355.524 35.7427C347.867 23.8187 337.078 15.5734 323.156 11.0068C309.234 6.44016 292.759 4.15684 273.733 4.15684H266V49.8231H278.605C290.439 49.8231 298.56 53.5018 302.969 60.8591C307.377 68.2165 309.582 78.4914 309.582 91.6839C309.582 100.817 308.538 108.682 306.449 115.278C304.593 121.621 301.228 126.568 296.356 130.12C291.483 133.418 284.638 135.067 275.821 135.067H266V174.264Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M432.72 12.1485C444.263 4.52847 459.69 0.484554 479 0.0166885V48.1895C474.849 48.8084 471.604 50.4946 469.266 53.2481C466.249 56.7999 464.277 61.3665 463.349 66.948C462.421 72.2757 461.957 77.984 461.957 84.0728V232.488C461.957 238.323 462.305 244.032 463.001 249.613C463.929 255.194 465.785 259.761 468.569 263.313C470.957 266.124 474.433 267.822 479 268.409V316.925C459.69 316.457 444.263 312.413 432.72 304.793C420.423 296.675 411.605 285.004 406.269 269.782C400.932 254.56 398.264 236.421 398.264 215.363V100.817C398.264 79.5062 400.932 61.3665 406.269 46.3981C411.605 31.4298 420.423 20.0132 432.72 12.1485Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M497.11 263.313C494.779 266.057 491.409 267.742 487 268.365V316.918C506.384 316.418 521.819 312.377 533.307 304.793C545.836 296.675 554.77 285.004 560.106 269.782C565.443 254.56 568.112 236.421 568.112 215.363V100.817C568.112 79.5062 565.443 61.3665 560.106 46.3981C554.77 31.4298 545.836 20.0132 533.307 12.1485C521.819 4.5649 506.384 0.523294 487 0.0236425V48.1956C491.409 48.8194 494.779 50.5036 497.11 53.2481C500.126 56.7999 502.098 61.3665 503.026 66.948C503.954 72.2757 504.419 77.984 504.419 84.0728V232.488C504.419 238.323 503.954 244.032 503.026 249.613C502.098 255.194 500.126 259.761 497.11 263.313Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M777.485 4.15684H741.07L707.658 183.397L676.681 4.15684H609.856L604.983 312.404H649.881L654.058 105.764L688.863 312.404H727.875L777.485 4.15684Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M762.013 150.641L766.13 312.404H810.332L805.459 4.15684H785.588L762.013 150.641Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M847.011 4.15684H909V312.404H847.011V4.15684Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M847.011 4.15684H909V312.404H847.011V4.15684Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M934.371 181.494H917V133.925H930.194C937.851 133.925 943.652 132.403 947.597 129.359C951.541 126.314 954.094 121.747 955.254 115.659C956.646 109.57 957.342 101.832 957.342 92.445C957.342 84.5802 956.762 77.7303 955.602 71.8952C954.674 65.8063 952.237 60.986 948.293 57.4342C944.348 53.8824 938.199 52.1064 929.846 52.1064H917V4.15684H941.68C958.618 4.15684 972.54 7.70867 983.446 14.8123C994.351 21.6622 1002.47 31.8103 1007.81 45.2565C1013.15 58.449 1015.81 74.559 1015.81 93.5866C1015.81 117.435 1012.22 135.701 1005.02 148.386C997.832 160.818 988.087 169.443 975.789 174.264C963.491 179.084 949.685 181.494 934.371 181.494Z"
        />
        <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 2 }}
          d="M1071.26 312.404V54.7703H1028.79V4.15684H1176.02V54.7703H1133.9V312.404H1071.26Z"
        />
        {/* <path 
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 1 }}
          d="M238.5,8h17.7H324l42.1,81.8L407,8h67.8v7.3l-64.2,127.5v-0.4l-44.1,87.9l-46.1-89.9V329h-64.2V69.4h-17.7V8z
            M474.8,37.6V329h-64.2V165L474.8,37.6z"
        /> */}
      </g>
    </svg>
  )
}

const DANG = ({ isLoaded, className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1850 317"
      className={cn(s.ei, className)}
    >
      <g fill={fill}>
        {/* <path
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 6 }}
          d="M613.6,8.1c10.4,0,20.1,1.9,29.1,5.6c9,3.7,16.9,8.8,23.7,15.4s12.2,14.1,16.1,22.8c3.9,8.7,5.8,18,5.8,28v15
            H624v-15c0-2.8-1.1-5.2-3.2-7.3c-2.1-2-4.7-3.1-7.6-3.1h-45.9c-2.9,0-5.4,1-7.4,2.9c-2,1.9-3,4.3-3,7.1v178.6
            c0,2.8,0.9,5.1,2.8,6.9c1.9,1.8,4.1,2.7,6.8,2.7h18.9v61.4h-18.9c-10.2,0-19.7-1.9-28.7-5.6c-9-3.7-16.8-8.8-23.5-15.2
            c-6.7-6.4-12-13.9-15.9-22.7c-3.9-8.7-5.8-17.9-5.8-27.6V79.5c0-9.7,1.9-18.9,5.8-27.6c3.9-8.7,9.2-16.3,16.1-22.8
            s14.7-11.6,23.7-15.4c9-3.7,18.7-5.6,29.1-5.6H613.6z M579.1,129.4h109.5v61.4h-73h-36.5V129.4z M624,200.5h64.2v57.2
            c0,9.7-1.9,18.9-5.8,27.6c-3.9,8.7-9.2,16.3-16.1,22.8c-6.8,6.5-14.7,11.7-23.7,15.4c-9,3.7-18.7,5.6-29.1,5.6h-18.1v-61.4h17.8
            c2.9,0,5.4-1,7.5-2.9c2.1-1.9,3.2-4.3,3.2-7.1V200.5z"
        />
        <path
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 4 }}
          d="M774.9,329.1h-66.2v-61.4h16.1l51.4-198.1h-15.6V8.1h31.7h33.3h0.4h14v0.4l0.8-0.4h32.9l64.2,321h-65.4
            l-12.8-63.7h-57.4l16.1-61.4h29.3l-17.7-86.8l-22.5,86.8h0.4l-16.1,61.4h-0.4l-1.2,4.6v0.8L774.9,329.1z"
        />
        <path
          className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }}
          d="M942.2,8.1h17.7h67.8l35.3,69.1v139.4l-38.9-76v188.5h-64.2V69.5h-17.7V8.1z M1073.9,329.1v-91.8l-0.4-0.8
            V97.2l0.4,0.8V8.1h63.8v321H1073.9z"
        />
        <path
          style={{ '--index': 5 }}
          className={cn(s.start, isLoaded && s.show)}
          d="M1278.9,8.1c10.4,0,20.1,1.9,29.1,5.6c9,3.7,16.9,8.8,23.7,15.4c6.8,6.5,12.2,14.1,16.1,22.8
            c3.9,8.7,5.8,18,5.8,28v15h-64.2v-15c0-2.8-1.1-5.2-3.2-7.3c-2.1-2-4.7-3.1-7.6-3.1h-45.9c-2.9,0-5.4,1-7.4,2.9
            c-2,1.9-3,4.3-3,7.1v178.6c0,2.8,0.9,5.1,2.8,6.9c1.9,1.8,4.1,2.7,6.8,2.7h18.9v61.4H1232c-10.2,0-19.7-1.9-28.7-5.6
            c-9-3.7-16.8-8.8-23.5-15.2c-6.7-6.4-12-13.9-15.9-22.7c-3.9-8.7-5.8-17.9-5.8-27.6V79.5c0-9.7,1.9-18.9,5.8-27.6
            c3.9-8.7,9.2-16.3,16.1-22.8c6.8-6.5,14.7-11.6,23.7-15.4c9-3.7,18.7-5.6,29.1-5.6H1278.9z M1244.4,129.4H1354v61.4h-73h-36.5
            V129.4z M1289.4,200.5h64.2v57.2c0,9.7-1.9,18.9-5.8,27.6c-3.9,8.7-9.2,16.3-16.1,22.8c-6.8,6.5-14.7,11.7-23.7,15.4
            c-9,3.7-18.7,5.6-29.1,5.6h-18.1v-61.4h17.8c2.9,0,5.4-1,7.5-2.9c2.1-1.9,3.2-4.3,3.2-7.1V200.5z"
        /> */}
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1274 0.0456576C1254.93 0.642211 1239.61 4.80332 1228.05 12.529C1215.75 20.9011 1207.05 32.8251 1201.95 48.3009C1196.84 63.523 1194.29 81.7895 1194.29 103.1V214.222C1194.29 235.025 1196.84 253.165 1201.95 268.641C1207.05 283.863 1215.75 295.787 1228.05 304.413C1239.61 312.138 1254.93 316.299 1274 316.896V268.23C1270.28 267.554 1267.26 266.042 1264.94 263.693C1261.93 260.142 1259.84 255.575 1258.68 249.994C1257.75 244.158 1257.29 237.816 1257.29 230.966V85.9756C1257.29 79.633 1257.64 73.6711 1258.33 68.0896C1259.26 62.2545 1261.23 57.4342 1264.25 53.6287C1266.63 50.8414 1269.88 49.0748 1274 48.329V0.0456576Z"/>
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1282 268.489V316.941C1301.61 316.567 1316.92 312.645 1327.94 305.174C1339.77 297.055 1348.01 286.146 1352.65 272.446C1357.53 258.493 1359.96 242.763 1359.96 225.258V198.619H1299.05V230.966C1299.05 237.055 1298.71 243.017 1298.01 248.852C1297.31 254.687 1295.57 259.507 1292.79 263.313C1290.47 266.265 1286.88 267.99 1282 268.489Z"/>
<path className={cn(s.start, isLoaded && s.show)}
 style={{ '--index': 3 }} d="M1282 48.0721V0C1301.38 0.365563 1316.58 4.16134 1327.59 11.3873C1339.43 18.7447 1347.78 29.1464 1352.65 42.5926C1357.53 56.0388 1359.96 71.5146 1359.96 89.02V115.659H1299.05V84.8339C1299.05 78.7451 1298.71 72.91 1298.01 67.3285C1297.54 61.7471 1295.92 57.1805 1293.14 53.6287C1290.81 50.4435 1287.09 48.5913 1282 48.0721Z"/>          
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1424.86 12.1485C1436.38 4.54748 1451.76 0.504743 1471 0.020255V48.2115C1466.92 48.8478 1463.72 50.5266 1461.41 53.2481C1458.39 56.7999 1456.42 61.3665 1455.49 66.948C1454.57 72.2757 1454.1 77.984 1454.1 84.0728V232.488C1454.1 238.323 1454.45 244.032 1455.15 249.613C1456.07 255.194 1457.93 259.761 1460.71 263.313C1463.08 266.094 1466.5 267.786 1471 268.39V316.921C1451.76 316.437 1436.38 312.394 1424.86 304.793C1412.57 296.675 1403.75 285.004 1398.41 269.782C1393.08 254.56 1390.41 236.421 1390.41 215.363V100.817C1390.41 79.5062 1393.08 61.3665 1398.41 46.3981C1403.75 31.4298 1412.57 20.0132 1424.86 12.1485Z"/>
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1489.25 263.313C1486.9 266.087 1483.48 267.778 1479 268.385V316.922C1498.45 316.438 1513.94 312.396 1525.45 304.793C1537.98 296.675 1546.91 285.004 1552.25 269.782C1557.59 254.56 1560.26 236.421 1560.26 215.363V100.817C1560.26 79.5062 1557.59 61.3665 1552.25 46.3981C1546.91 31.4298 1537.98 20.0132 1525.45 12.1485C1513.94 4.54608 1498.45 0.503255 1479 0.019988V48.1756C1483.48 48.7829 1486.9 50.4737 1489.25 53.2481C1492.27 56.7999 1494.24 61.3665 1495.17 66.948C1496.1 72.2757 1496.56 77.984 1496.56 84.0728V232.488C1496.56 238.323 1496.1 244.032 1495.17 249.613C1494.24 255.194 1492.27 259.761 1489.25 263.313Z"/>
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1598.52 4.15684V312.404H1660.82V4.15684H1598.52Z"/>
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1797 133.335L1746.71 4.15684H1702.86V312.404H1756.11V152.572L1797 265.691V133.335Z"/>
<path className={cn(s.start, isLoaded && s.show)}
          style={{ '--index': 3 }} d="M1805 287.822L1813.89 312.404H1856V4.15684H1805V287.822Z"/>                                                  
      </g>
    </svg>
  )
}
