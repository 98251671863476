import { useRef, useEffect, useState } from 'react';
import { useAudio } from 'hooks/use-audio'
import anime from 'animejs/lib/anime.es.js'
import { Button } from 'components/button'
import { useTranslation } from "react-i18next";
import s from './adchat.module.scss'

const numberOfEls = 90;
const duration = 6000;
const delay = duration / numberOfEls;

let tl = anime.timeline({
  duration: delay,
  complete: function() { tl.restart(); }
});

export const Adchat = () => {
  const indicatorEl = useRef(null);

  function createEl(i) {
    let el = document.createElement('div');
    const rotate = (360 / numberOfEls) * i;
    const translateY = -50;
    const hue = Math.round(360 / numberOfEls * i);
    el.classList.add(s.line);
    el.style.backgroundColor = 'hsl(' + hue + ', 40%, 60%)';
    el.style.transform = 'rotate(' + rotate + 'deg) translateY(' + translateY + '%)';
    tl.add({
      begin: function() {
        anime({
          targets: el,
          backgroundColor: ['hsl(' + hue + ', 40%, 60%)', 'hsl(' + hue + ', 60%, 80%)'],
          rotate: [rotate + 'deg', rotate + 10 +'deg'],
          translateY: [translateY + '%', translateY + 10 + '%'],
          scale: [1, 1.25],
          easing: 'easeInOutSine',
          direction: 'alternate',
          duration: duration * .1
        });
      }
    });
    indicatorEl.current.appendChild(el);
  };

  useEffect(() => {
    for (let i = 0; i < numberOfEls; i++) createEl(i);
  }, []);

  const [playing, setPlaying] = useState(false);
  const { pause, play } = useAudio(process.env.PUBLIC_URL + '/audios/adchat.m4a')
  const outerEl = useRef(null);

  const [timer, setTimer] = useState(null);
  const setPlay = (ev) => {
    ev.preventDefault();
    setTimer(setTimeout(() => {
      setPlaying(true)
      play()
      outerEl.current.classList.add(s.show)
    }, 500))
  };
  const setStop = () => {
    clearTimeout(timer);
    if (playing) {
      setPlaying(false)
      pause()
      outerEl.current.classList.remove(s.show)
    }
  };

  const jump = () => {
    window.location.href = 'http://adchat.dmgang.com'
  }

  const { t } = useTranslation();
  return (
    <div className={s.adchat}>
      <div className={s.outer} ref={outerEl} >
        <div className={s.indicator} ref={indicatorEl}></div>
      </div>
      <div>
        {/* <Button className={s.btn}>{t('hold to enter adchat mode')}</Button> */}
      </div>
    </div>
  )
}