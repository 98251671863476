import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      'zh-CN': {
        translations: {
          'stay tuned': '养精蓄锐，等等再來吧',
          'open in browser': '點擊右上角選擇瀏覽器打開',
          'junk wechat': '請用手機內置瀏覽器打開鏈接，獲得更好的視覺體驗',
          'scroll': '滾動',
          'to explore': '探索',
          'dmgang never text 1': '你需要帶上自己的顛覆基因，',
          'dmgang never text 2': '因為這個世界已經被AI顛覆了。',
          'dmgang overview': `IP資產代幣化\n交易平臺`,
          'dmgang story': 'AI+HI生成原創IP',
          'dmgang story content': `
          你不需要會漫畫，也可以成為一部流行漫畫的作者和版權擁有者你不許要會寫作，也可以輕鬆完成一部100萬字的網路小說，向promptcoin平臺貢獻你的提示詞，自動生成內容
          `,
          'introduction': '海量知名IP引入',
          'introduction content': `
          在promptcoin眾創平臺，完成一部作品後，通過人工智慧計算每個參與者的有效貢獻，平臺立刻將每個人的版權份額確認上鏈並mint成NFT，讓你的創意變成數位資產。
          `,
          'first city': 'IP資產碎片化',
          'first city content': `
            將IP通過ERC721鑄造成NFT，\n
            並且折開成若干數量的ERC20版權碎片，\n
            每個碎片代表IP版權的一部分權益並且可以自由交易。\n
            通過智慧合約可將全部數量的ERC20版權碎片自動收回，\n
            從而支持買家的買斷行為。\n
           
          `,
          'nft introduction': `平臺幣使用場景`,
          'nft introduction content': `
            購買版權碎片；<3 /><3 />
            銷毀PC獲得參與創作的門票；<3 /><3 />
            銷毀PC向平臺購買道具；<3 /><3 />
            以質押PC獲得版權碎片的形式對IP進行投票；<3 /><3 />
            IP授權收益的支付；<3 /><3 />
            質押PC參與平臺治理。<3 /><3 />
          `,
          'design01': `NFT\n一代英雄`,
          'design01 content': `
            一代英雄NFT極為稀缺，為圈內行為低調目光長遠的投資人/機构/社群大佬所專有。\n
            發行量：54枚（香港地區不再增發）\n
            發行方式：投盲標單價：底價400 USDT。\n
            賦能：每個一代NFT可鑄造20枚二代英雄。NFT包含IDO價格的8折認購價值為20% x 投標價格的平臺幣私募份額，鎖倉期100日。
          `,
          'design02': `NFT\n二代英雄`,
          // 'design02 content': `
          //   二代英雄NFT較為稀缺，為圈內有影響力/口碑良好的意見領袖/內容創意者所專有。\n
          //   發行量：1,080枚（香港地區不再增發）。\n
          //   發行方式：定價銷售。單價：300 USDT。\n
          //   賦能：可鑄造100枚三代英雄。NFT包含IDO價格8.5折認購價值為60 USDT的平臺幣私募份額，鎖倉期100日。
          // `,
          'design02 content': `细节代公布`,
          'design03': `NFT\n三代英雄`,
          // 'design03 content': `
          //   三代英雄NFT屬於圈內那些年輕/有活力/有創意/身強力壯/自豪於通過貢獻智慧獲得巨大收益的活躍分子。\n
          //   發行量：108,000枚（香港地區不再增發）。\n
          //   發行方式：定價銷售。單價：底價 200 USDT。賦能：NFT包含按IDO價格9折認購的價值為20 USDT的平臺幣私募份額，鎖倉期100日。
          // `,
          'design03 content': `细节待公布`,
          'token mechanism': `NFT\n綁定代幣機制`,
          'token mechanism content': `每個NFT與私募代幣份額為綁定關係，幫派成員想拋售代幣時，不得不放弃NFT本體，該NFT自動歸屬平臺，平臺在二級市場銷售該NFT所得，在本支系幫派內平均分配。NFT持有者可以在OTC市場將NFT本體和Token一併出售，但Token發放依然跟隨私募鎖倉機制，意味著隨著NFT轉售，市面上並沒有釋放更多的Token。`,
          'earning model': `NFT\n收益模式`,
          'earning model content': `
            通過你分享的鏈接鑄造的每枚二代英雄NFT銷售額中你將獲得20 USDT收益，每枚三代英雄NFT銷售額中你將獲得5 USDT收益。預計可獲得10,400 USDT的收益。\n
            一代英雄可以搶占元宇宙香港的地盘，共划分18个区域，每個區域支持三位英雄入駐，你所搶占的區域所產生的廣告費你將擁有分紅權。\n
            當你成为二代英雄NFT持有者，則預計可獲得3,000 USDT的收益。三代英雄NFT不產生銷售收益。\n
            所有英雄可以利用平台的AI技術自動化生成推廣內容，參與任意Promote to Earn都將獲得廣告收益，當所在區域產生廣告費時，二三代英雄會獲得收益分配。
          `,
          'view white paper': `
            查看 <1 />
            <2>
              白皮書
            </2>
          `,
          'scrool to explore': '滾動繼續探索',
          'adchat info': '你好，我是<1>promptAI</1>，我是全球第一款帶有人工智慧基因的眾創平臺。要試試嗎？你要有顛覆基因，因為世界已經被AI顛覆了。',
          'hold to enter adchat mode': '點擊進入 promptAI 模式',
          'q1 2023': `
            AI產品試用版測試
          `,
          'q2 2023': `
            邀請測試用戶；
            開始私募；
            IDO
          `,
          'q3 2023': `
            上線關注度算法功能
            上線Swap功能
          `,
          'q4 2023': `
            推出AI語言自我調整社群
          `,
          // 'q1 2024': `
          //   上線多種組合廣告產品
          //   上線線下店鋪支付解決方案
          // `,
          'q2 2024': `公開2024年白皮書`,
          'team introduction': '團隊成員',
          'project coordinator': '創始人',
          'coordinator': '12年互聯網和眾創內容平台運營研發經驗，5年區塊鏈內容行業經驗。',
          'head of market pr capital management': 'AI 技術專家',
          'management': '擁有15年的技術開發經驗，AI 技術探索者。',
          'technical director': '商務負責人',
          'director': '區塊鏈投資者。',
          'cto': '市場拓展/IP運營負責人',
          'cto content': '多年IP投資和IP交易管理經驗。',
          'blockchain developer': '產品開發負責人',
          'developer': '豐富的互聯網開發管理經驗',
          'product manager': '產品負責人',
          'nft designer': 'NFT設計師',
          'ribenip': '日本IP開發負責人',
          'ribenip name': '宍戸 英治',
          'ribenipjy': '17年日本漫畫策劃經驗，日本音樂導演，10年漫畫IP運營經驗，負責日本漫畫大IP拓展',
          'footer text 1': `
            我们 <1>從不</1> <3 />
            按規則行事。
          `,
          'footer text 2': `
            我們招募 <1 />
            最<2>無情</2>的成員<1 />
            加入幫派
          `,
          'footer text 3': `
            我们 <1>從不</1> <3 />
            按規則行事。 <3 />
            我們招募<3 />
            最<1>無情</1>的成員
            加入幫派
          `,
          'Institutions': '組織',
          'trial': '內容合作夥伴',
          'test': 'Promptcoin認証資格',
          'backed': '機构夥伴',
        }
      },
      'en-US': {
        translations: {
          'stay tuned': 'STAY TUNED',
          'open in browser': 'Click and select to open in browser',
          'junk wechat': 'Please use the mobile phone built-in browser to open the link for a better visual experience',
          'scroll': 'scroll',
          'to explore': ' to explore',
          'dmgang never text 1': 'you need to bring your own subversive genes,',
          'dmgang never text 2': 'because the world has already been disrupted by AI.',
          'dmgang overview': 'IP asset tokenization trading platform',
          'dmgang story': 'AI+HI generate original IP',
          'dmgang story content': `
            You don't need to know how to draw manga to become the creator and copyright holder of a popular manga.\n
            You don't have to be a writer to easily complete a 1 million-word web novel. Simply contribute your prompts to the Promptcoin platform, and the content will be generated automatically.
          `,
          'introduction': 'Introduction of massive well-known IPs',
          'introduction content': `
           On the Promptcoin platform, once a work is completed, the effective contribution of each participant is calculated by artificial intelligence, and the platform immediately confirms each person's copyright share on the blockchain and mints it into an NFT, turning your creativity into a digital asset.
          `,
          'first city': 'Fragmentation of IP assets',
          'first city content': `
            IPs are cast into NFTs through ERC721,\n
            and are further broken down into several ERC20 copyright fragments, \n
            each of which represents a portion of the IP's rights and can be freely traded. \n
            Smart contracts can collect all ERC20 copyright fragments automatically, \n
            thus supporting the buyer's buyout behavior.
          `,
          'nft introduction': 'Utility of the platform token',
          'nft introduction content': `
            Purchase of copyright fragments;<3 />
            Destruction of PC to obtain tickets to participate in creation;<3 />
            Destruction of PC to purchase props from the platform;<3 />
            Voting on IP through pledging PC to obtain copyright fragments;<3 />
            Payment of IP licensing revenue;<3 />
            Pledging of PC to participate in platform governance.<3 />
          `,  
          'design01': 'Design01',
          'design01 content': `
            Generation I NFT is extremely scarce, and it is exclusive to investors/institutions/community leaders with low-key  behavior and long-term vision in the circle.Circulation:\n
            54 pieces (no additional issuance in Hong Kong).\n
            Issuance method: Blind bidding.Unit price: Start price 400 USDT.\n
            Empowerment: Each Generation I NFT can mint 20 pieces of Generation II NFTs. NFT contains a 20% discount on the IDO price. The subscription value is 20% x the bidding price of the platform currency private placement share, and the lock-up period is 100 days.NFT holders will receive the right to share dividends on the Promptcoin platform.
          `,
          'design02': 'Design02',
          // 'design02 content': `
          //   Generation II NFT is relatively scarce, and it is exclusive to influential/good reputation opinion leaders/content creators in the circle.\n
          //   Circulation: 1,080 pieces (no additional issuance in Hong Kong).\n
          //   Distribution method: fixed price sale.Unit price: 300 USDT.\n
          //   Empowerment: Each Generation II NFT can mint 100 Generation III NFTs. The NFT includes a 15% discount on the IDO price and a subscription value of 60 USDT for the private placement of platform tokens, with a lock-up period of 100 days.NFT holders will receive the right to share dividends on the DMGang platform.
          // `,
          'design02 content': `Details to be announced`,
          'design03': 'Design03',
          // 'design03 content': `
          //   Generations III NFT belongs to those active members in the circle who are young/vigorous/creative/strong/proud to gain huge benefits by contributing their wisdom.\n
          //   Circulation: 108,000 pieces (no additional issuance in Hong Kong).\n
          //   Distribution method: fixed price sale.Unit price: The base price is 200 USDT.\n
          //   Empowerment: The NFT contains a 20 USDT private placement share of the platform currency subscribed at a 10% discount on the IDO price.NFT holders will receive the right to share dividends on the DMGang platform.
          // `,
          'design03 content': `Details to be announced`,
          'token mechanism': 'Token Mechanism',
          'token mechanism content': `
            Each NFT is binding with the share of private placement tokens.\n
            When Gang members want to sell tokens, they have to return the NFT to the platform.\n
            Platform will sell the NFT in the secondary market.\n
            And share the income with other Gang members.\n
            NFT holders can sell the NFT and Token together in the OTC market, but the issuance of Token still follows the private lock-up period, which means that with the resale of NFT, no more Tokens are released on the market.
          `,
          'earning model': 'Earning Model',
          'earning model content': `
            When you become a Generation I NFT holder, you can mint 20 NFTs and sale, and you will get 20 USDT in the sales of each Generation II NFT minted through the link you share, and each third-generation hero NFT sales You will get a profit of 5 USDT.\n
            It is estimated that you can get a profit of 10,400 USDT.Generation I of heroes can seize the territory of Metaverse Hong Kong, which is divided into 18 regions, and each region supports three heroes to settle in.\n
            You will have the right to share the advertising incomes generated by the regions you occupy.\n
            When you become a Generation II NFT holder, you are expected to earn 3,000 USDT in return.\n
            Generations III NFT holders does not generate sales revenue.\n
            All heroes can use the platform's AI technology to automatically generate promotional content. Participating in any Promote to Earn will earn advertising revenue.\n
            When advertising incomes are generated in the area, the Generation II and III holders can earn the revenue distribution of advertising incomes also.
          `,
          'view white paper': `
            VIEW <1 />
            <2>
              WHITE PAPER.Doc
            </2>
          `,
          'scrool to explore': 'SCROLL TO EXPLORE',
          'adchat info': `
            Hi, I am <1>PromptAI</1>, 
            I am the world's first crowd-creation platform with artificial intelligence genes. 
            Wanna try? You need  to have subversive genes, 
            because the world has already been disrupted by AI.
          `,
          'hold to enter adchat mode': 'Click to Enter PromptAI Mode',
          'q1 2023': `
            AI product trial version testing
          `,
          'q2 2023': `
          nvite testing users;
          Start private sale;
          IDO (Initial DEX Offering).
          `,
          'q3 2023': `
            Launch promotion reward calculation function;
            Launch Swap function.
          `,
          'q4 2023': `
            Launch of AI language-adaptive community.
          `,
          // 'q1 2024': `
          //   Launch a variety of combined advertising products;
          //   Launch offline store payment system solution.
          // `,
          'q2 2024': `Launch the Whitepaper 2024.`,
          'team introduction': `
            TEAM
            <1 />
            <2>MEMBERS</2>
          `,
          'project coordinator': 'FOUNDER',
          'coordinator': 'With 12 years of experience in the internet and media industry, and 5 years of experience in the blockchain industry.',
          'head of market pr capital management': 'AI Product Expert',
          'management': 'With 15 years of technical development experience and a pioneer in AI technology.',
          'technical director': 'Business Manager',
          'director': 'And blockchain investor.',
          'cto': 'Community Manager',
          'cto content': 'With years of experience in managing blockchain communities.',
          'blockchain developer': 'Product Development Manager',
          'developer': 'With extensive experience in internet development management.',
          'product manager': 'Product Manager',
          'nft designer': 'NFT Designer',
          'ribenip': 'Person in charge of IP development in Japan.',
          'ribenipjy': '17 years of experience in planning Japanese manga, a music director in Japan, and 10 years of experience in operating manga IP.',
          'ribenip name': 'Shishido Eiji',
          'footer text 1': `
            WE <1>NEVER</1> <3 />
            PLAY BY THE <3 />
            RULES of LOGIC.
          `,
          'footer text 2': `
            WE RECRURT THE MOST <1 />
            <2>RUTHLESS</2> MEMBERS <1 />
            TO OUR GANG
          `,
          'footer text 3': `
            WE <1>NEVER</1> <3 />
            PLAY BY THE <3 />
            RULES of LOGIC.<3 />
            WE RECRURT THE MOST
            RUTHLESS MEMBERS
            TO OUR GANG.
          `,
          'Institutions': 'Institutions',
          'trial': 'Content Partners',
          'test': 'Qualification activation',
          'backed': 'backed by',
        }
      },
      'ja': {
        translations: {
          'stay tuned': '精養備魏、続くのを待つ',
          'open in browser': '右上隅をクリックしてブラウザで開く',
          'junk wechat': '携帯内蔵ブラウザでリンクを開くと、より良い視覚体験が得られます。',
          'scroll': 'スクロール',
          'to explore': '探索する',
          'dmgang never text 1': 'あなたはあなた自身のサブバージョンジーンを持ち込む必要があります。，',
          'dmgang never text 2': 'というのは、この世界は既にAIによって覆されていますから。',
          'dmgang overview': `IPアセットトークン化取引プラットフォーム`,
          'dmgang story': 'AI + HIによるオリジナルIP生成',
          'dmgang story content': `
            漫画を描くことができなくても、人気のある漫画の作者や著作権所有者になることができます。\n 
            作家である必要はなく、簡単に100万ワードのウェブ小説を完成させることができます。\n
            Promptcoinプラットフォームにあなたのプロンプトを貢献するだけで、自動的にコンテンツが生成されます。 
          `,
          'introduction': '多数の名高いIPの導入',
          'introduction content': `
          Promptcoinのクリエイティブプラットフォームで作品を完成させると、人工知能により有効な貢献度が計算され、各参加者の権利を確認し、それぞれのNFTが作成されます。あなたの創造力をデジタル資産に変えます。
          `,
          'first city': 'IPアセットの断片化',
          'first city content': `
            IPをERC721によって鋳造してNFTにすることで、数多くのERC20版権断片に分割し、\n
各断片がIP版権の一部権益を代表し、自由に取引できるようにします。\n
スマートコントラクトを通じて、全数のERC20版権断片を自動的に回収し、\n
バイヤーの買い切り行為をサポートします。
          `,
          'nft introduction': `プラットフォームトークンの使用シーン`,
          'nft introduction content': `
          版権断片を購入すること；<3 /><3 />
PCを銷毀して、創作に参加するチケットを手に入れること；<3 /><3 />
PCを銷毀して、プラットフォームからアイテムを購入すること；<3 /><3 />
PCを担保にして、IPに投票するための版権断片を手に入れること；<3 /><3 />
IPライセンス収益の支払い；<3 /><3 />
PCを担保にして、プラットフォームの統治に参加すること。
          `,
          'design01': `NFT\n第1世代ヒーロー`,
          'design01 content': `
            一代英雄NFTは非常に希少であり、長期的な視野を持ち、控えめで影響力のある投資家/機関/コミュニティの指導者が所有しています。\n 
            発行量：54枚（香港地区では増発しません）\n 
            発行方法：盲目入札単価：最低価格400 USDT。\n 
            能力：1つの1代NFTにつき、2代英雄を20枚鋳造できます。NFTにはIDO価格の8割の購入価値が含まれ、投標価格の20%のプラットフォームトークンのプライベートセールをロックすることができます。ロック期間は100日間です。
          `,
          'design02': `NFT\n第2世代ヒーロー`,
          // 'design02 content': `
          //   二代英雄NFT較為稀缺，為圈內有影響力/口碑良好的意見領袖/內容創意者所專有。\n
          //   發行量：1,080枚（香港地區不再增發）。\n
          //   發行方式：定價銷售。單價：300 USDT。\n
          //   賦能：可鑄造100枚三代英雄。NFT包含IDO價格8.5折認購價值為60 USDT的平臺幣私募份額，鎖倉期100日。
          // `,
          'design02 content': `詳細は後日公開`,
          'design03': `NFT\n第3世代ヒーロー`,
          // 'design03 content': `
          //   三代英雄NFT屬於圈內那些年輕/有活力/有創意/身強力壯/自豪於通過貢獻智慧獲得巨大收益的活躍分子。\n
          //   發行量：108,000枚（香港地區不再增發）。\n
          //   發行方式：定價銷售。單價：底價 200 USDT。賦能：NFT包含按IDO價格9折認購的價值為20 USDT的平臺幣私募份額，鎖倉期100日。
          // `,
          'design03 content': `詳細は後日公開`,
          'token mechanism': `NFT\nトークンバインディングメカニズム`,
          'token mechanism content': `各NFTは、プライベートトークンの割り当てとバインドされています。マフィアメンバーがトークンを売却する場合、NFT本体を放棄する必要があり、そのNFTは自動的にプラットフォームに帰属し、プラットフォームが二次市場で得た収益は、このサポートファミリー内で平等に分配されます。NFT所有者は、OTC市場でNFT本体とトークンを一緒に販売できますが、トークンは引き続きプライベートセールのロック期間に従って発行されます。これは、NFTの転売に伴って、市場により多くのトークンが投入されないことを意味します。`,
          'earning model': `NFT\n収益モデル`,
          'earning model content': `
            あなたが共有リンクから鋳造した2代英雄NFTの販売額により、あなたは20 USDTの利益を得ることができます。3代英雄NFTの販売額により、あなたは5 USDTの利益を得ることができます。あなたは10,400 USDTの収益を見込んでいます。\n
             1代英雄は香港のメタバースの地位を占めることができ、18の地域に分けられており、各地域に3人の英雄が入居することができます。あなたが占める地域から生じる広告費はあなたが分配権を持つものとなります。\n 
             2代英雄NFTの所有者となると、あなたは3,000 USDTの収益を見込めます。3代英雄NFTには販売収益はありません。\n 
             すべての英雄は、プラットフォームのAI技術を利用して自動的にプロモーションコンテンツを生成することができます。任意のPromote to Earnに参加すると、広告収益が得られ、地域が広告費を生み出した場合、2代および3代英雄は収益を分配されます。
          `,
          'view white paper': `
            を表示 <1 />
            <2>
              白書
            </2>
          `,
          'scrool to explore': 'スクロールして探索を続ける',
          'adchat info': 'こんにちは、私は<1>PromptAI</1>,世界初の人工知能遺伝子を持つクラウドクリエーションプラットフォームです。試してみませんか？あなたには革命的な遺伝子が必要です。なぜなら、世界は既にAIによって変革されているからです。',
          'hold to enter adchat mode': 'クリックしてPromptAIモードに入る',
          'q1 2023': `
            AI製品のトライアル版テスト
          `,
          'q2 2023': `
            テストユーザーの招待； 
            プライベートセール開始； 
            IDO 
          `,
          'q3 2023': `
            注目度アルゴリズム機能のオンライン化
            Swap機能のオンライン化
          `,
          'q4 2023': `
            AI言語適応型コミュニティをリリース
          `,
          // 'q1 2024': `
          //   上線多種組合廣告產品
          //   上線線下店鋪支付解決方案
          // `,
          'q2 2024': `2024年の白書を公開する`,
          'team introduction': 'チームメンバー',
          'project coordinator': '創設者',
          'coordinator': '12年のインターネットおよびメディア業界経験、5年のブロックチェーン業界経験があります。',
          'head of market pr capital management': 'AI技術専門家',
          'management': '15年の技術開発経験があり、AI技術の先駆者です。',
          'technical director': 'ビジネスマネージャーで',
          'director': 'ブロックチェーン投資家でもあります。',
          'cto': 'コミュニティマネージャーで',
          'cto content': 'ブロックチェーンコミュニティの管理経験が長年あります。',
          'blockchain developer': '製品開発責任者で',
          'developer': 'インターネット開発管理の豊富な経験を持っています。',
          'product manager': '製品責任者',
          'nft designer': 'NFTデザイナー',
          'ribenip': '日本のIP開発責任者',
          'ribenipjy': '17年間の日本漫画の企画経験と日本音楽監督、そして10年間の漫画IPの運用経験',
          'ribenip name': '宍戸 英治',
          'footer text 1': `
            私たちは<1>決して</1> <3 />
            規則に従いません
          `,
          'footer text 2': `
            私たちはを募集しています<1 />
            最も<2>無慈悲な</2><1 /> 
            ギャングに<1 />加入してください
          `,
          'footer text 3': `
            私たちはをしません
            ルールに従うことはありません
            私たちは
            を募集しています
            最も<1>無慈悲な</1>メンバーを
            ギャングに加入してください
          `,
          'Institutions': '組織',
          'trial': 'コンテンツパートナ',
          'test': 'Promptcoin資格認定',
          'backed': 'backed by',
        }
      },
    },
    fallbackLng: "en-US",
    // debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
