import { useState, useImperativeHandle } from 'react';
import s from './stay-view.module.scss'
import { useTranslation } from "react-i18next";
import { Button } from 'components/button'

export const StayView = ({ cRef }) => {
  const { t } = useTranslation()
  const [ isShow, setIsView ] = useState(false)

  useImperativeHandle(cRef, () => ({
    setIsView: (newVal) => {
      setIsView(newVal);
      console.log(cRef,'val')
    }
}));

  return isShow ? (
    <div className={s.bg}>
      <img className={s.img} src={process.env.PUBLIC_URL + '/dialog.svg'} alt='stay-tuned' />
      <Button className={s.btn} onClick={() => setIsView(false)}>
        {t('stay tuned')}
      </Button>
    </div>
  ) : (<></>)
}