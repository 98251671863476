import {useEffect, useRef, useState} from 'react'
import u from './user-list.module.scss'
import image from 'icons/test.png'

const jump = (url) => {
    if(!url) return
    window.open(url)
  }
export const UserList = ({users}) => {

    return (
        <div className={u.wrapper}>
            <div className={u.inner}>
                {/*<p className={u.title}>DMGang用户</p>*/}
                <div style={{display: 'flex'}}>
                    <div className={u.list}>
                        {users.map((user, index) => {
                            return (
                                // <div className={u.item} key={user.id}>
                                <img key={index} style={{display: 'block'}} src={process.env.PUBLIC_URL + user.avatar} onClick={()=>{jump(user.href)}} alt=""/>
                                // <p className={u.name}>{user.name}</p>
                                // </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}