import cn from 'clsx'
import { Link } from 'components/link'
import s from './list-item.module.scss'

export const ListItem = ({
  className,
  title,
  text,
  source,
  national,
  href,
  visible,
  index,
}) => {
  return (
    <Link
      href={href}
      className={cn(className, s.item, visible && s.visible)}
      style={{ '--i': index }}
    >
      <div className={s.inner}>
      <div className={s.source}>
          <span>{source}</span>
          <span className={s.text_context}>{national}</span>
        </div>
        <div className={s.title}>
          <span className={s.text}>{title}</span>
          <span className={s.text_context}>{text}</span>
        </div> 
      </div>
    </Link>
  )
}
