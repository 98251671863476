import cn from 'clsx'
import s from './card.module.scss'

export const Card = ({
  number,
  title,
  text,
  className,
  inverted,
  textSize = 'small',
  background = 'rgba(14, 14, 14, 0.15)',
}) => {
  return (
    <div
      className={cn(className, s.wrapper, inverted && s.inverted)}
      style={{ '--background': background }}
    >
      {number && (
        <p className={s.number}>{number.toString().padStart(2, '0')}</p>
      )}
      <div>
        {title && <p className={s.title}>{title}</p>}
        {text && <p className={`${s[textSize]} ${s.text}`}>{text}</p>}
      </div>
    </div>
  )
}
