import { useEffect, useState } from 'react';

export function useAudio(url) {
  const [audio] = useState(new Audio(url));

  useEffect(() => {
    audio.loop = true;
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  const pause = () => {
    audio.pause();
  };

  const play = () => {
    audio.play();
  };

  return { pause, play };
}
