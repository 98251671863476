import { useRect } from '@studio-freight/hamo'
import cn from 'clsx'

import { Card } from 'components/card'
import { AppearTitle } from 'components/appear-title'
import { useScroll } from 'hooks/use-scroll'
import { clamp, mapRange } from 'lib/maths'
import { useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { useTranslation } from "react-i18next";

import s from './feature-cards.module.scss'


export const FeatureCards = () => {
  const element = useRef()
  const [setRef, rect] = useRect()
  const { height: windowHeight } = useWindowSize()

  const [current, setCurrent] = useState()

  useScroll(
    ({ scroll }) => {
      const start = rect.top - windowHeight * 2
      const end = rect.top + rect.height - windowHeight

      const progress = clamp(0, mapRange(start, end, scroll, 0, 1), 1)

      element.current.style.setProperty(
        '--progress',
        clamp(0, mapRange(rect.top, end, scroll, 0, 1), 1)
      )
      const step = Math.floor(progress * 7)
      setCurrent(step)
    },
    [rect]
  )

  const { t } = useTranslation();
  const cards = [
    { title: 'Q1_2023', text: t('q1 2023') },
    { title: 'Q2_2023', text: t('q2 2023') },
    { title: 'Q3_2023', text: t('q3 2023') },
    { title: 'Q4_2023', text: t('q4 2023') },
    // { title: 'Q1_2024', text: t('q1 2024') },
    { title: 'Q1_2024', text: t('q2 2024') }
  ]

  return (
    <div
      ref={(node) => {
        setRef(node)
      }}
      className={s.features}
    >
      <div className={cn('layout-block-inner', s.sticky)}>
        <aside className={s.title}>
          <p className="h3">
            <AppearTitle>
              ROAD MAP
            </AppearTitle>
          </p>
        </aside>
        <div ref={element}>
          {cards.map((card, index) => (
            <SingleCard
              key={index}
              index={index}
              text={card.text}
              number={card.title}
              current={index <= current - 1}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const SingleCard = ({ text, number, index, current }) => {
  return (
    <div className={cn(s.card, current && s.current)} style={{ '--i': index }}>
      <Card background="rgba(239, 239, 239, 0.8)" number={number} text={text} textSize="large" />
    </div>
  )
}
