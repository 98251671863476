import cn from 'clsx'
import { useRef } from 'react'
import { Button } from 'components/button'
import { Link } from 'components/link'
import s from './footer.module.scss'
import { ReactComponent as GitHub } from 'icons/github.svg'
import { ReactComponent as Twitter } from 'icons/twitter.svg'
import { Trans } from "react-i18next";
import { StayTuned } from 'components/stay-tuned'

export const Footer = () => {

  const dialogRef = useRef(null)
  const setIsShow = () => {
    dialogRef.current.setIsShow(true);
  }

  const openUrl = () => {
    window.open('https://ai.promptcoin.me/')
  }

  return (
    <footer className={cn('theme-light', s.footer)}>
      <StayTuned cRef={dialogRef} />
      <div className={cn(s.top, 'layout-grid hide-on-mobile')}>
        <p className={cn(s['first-line'], 'h2')}>
          <Trans i18nKey="footer text 1">
            WE <span className="contrast">NEVER</span> <br />
            PLAY BY THE <br />
            RULES of LOGIC.
          </Trans>
        </p>
        <div className={s['shameless-plug']}>
          <p className="h4">prompt to earn</p>
        </div>
        <p className={cn(s['last-line'], 'h2')}>
          <Trans i18nKey="footer text 2">
            WE RECRURT THE MOST <br />
            <span className="contrast">RUTHLESS</span> MEMBERS <br />
            TO OUR GANG
          </Trans>
        </p>
        <div className={s.ceshi} onClick={openUrl}>
            <p className="p-xs">
              <Trans i18nKey="test">
              </Trans>
              </p>
        </div>         
        <Button
          className={s.cta}
          arrow
          icon={<GitHub />}
          onClick={setIsShow}
        >
          MINT NFT
        </Button>
      </div>
      <div className={cn(s.top, 'layout-block hide-on-desktop')}>
        <div className={s['shameless-plug']}>
          <p className="h4">prompt to earn</p>
        </div>
        <p className={cn(s['first-line'], 'h2')} style={{ fontSize: '12vw' }}>
          <Trans i18nKey="footer text 3">
            WE <span className="contrast">NEVER</span> <br />
            PLAY BY THE <br />
            RULES of LOGIC.<br />
            WE RECRURT THE MOST
            <span className="contrast">RUTHLESS</span> MEMBERS
            TO OUR GANG.
          </Trans>
        </p>
      </div>
      <div className={s.bottom}>
        <div className={s.links}>
          <Link className={cn(s.link, 'p-xs')} href="#overview">
            OVERVIEW
          </Link>
          <Link className={cn(s.link, 'p-xs')} href="#nft-introduction">
            CONTENT PARTNER
          </Link>
          <Link className={cn(s.link, 'p-xs')} href="#doc">
            DOC
          </Link>
          <Link className={cn(s.link, 'p-xs')} href="#roadmap">
            ROADMAP
          </Link>
          <Link className={cn(s.link, 'p-xs')} href="#team">
            TEAM
          </Link>
          <Link className={cn(s.link, 'p-xs')} href="https://twitter.com/promptcoin">
            <Twitter className={s.icon} />
          </Link>
        </div>
        <p className={cn('p-xs', s.tm)}>
          <span>©</span> {new Date().getFullYear()} prompt to earn
        </p>
        <div className={cn(s.ceshi1,'hide-on-desktop')}  onClick={openUrl}>
            <span className="p-xs"><Trans i18nKey="test">
              </Trans></span>
        </div>
        <Button
          className={cn(s.cta, 'hide-on-desktop')}
          arrow
          icon={<GitHub />}
          onClick={setIsShow}
        >
          MINT NFT
        </Button>
      </div>
    </footer>
  )
}
