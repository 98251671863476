import { useState, useImperativeHandle } from 'react';
import s from './stay-tuned.module.scss'
import { useTranslation } from "react-i18next";
import { Button } from 'components/button'

export const StayTuned = ({ cRef }) => {
  const { t } = useTranslation()
  const [ isShow, setIsShow ] = useState(false)

  useImperativeHandle(cRef, () => ({
    setIsShow: (newVal) => {
      setIsShow(newVal);
    }
}));

  return isShow ? (
    <div className={s.bg}>
      <img className={s.img} src={process.env.PUBLIC_URL + '/stay-tuned.svg'} alt='stay-tuned' />
      <Button className={s.btn} onClick={() => setIsShow(false)}>
        {t('stay tuned')}
      </Button>
    </div>
  ) : (<></>)
}