import { useFrame } from '@studio-freight/hamo'
import cn from 'clsx'
import { Footer } from 'components/footer'
import { Intro } from 'components/intro'
import { Scrollbar } from 'components/scrollbar'
import { Cursor } from 'components/cursor'
import { useStore } from 'lib/store'
import { useEffect, useState } from 'react'
import Lenis from 'lib/lenis.js'
import s from './layout.module.scss'

export function Layout({
  seo = { title: '', description: '', image: '', keywords: '' },
  children,
  theme = 'light',
  className,
}) {
  const [lenis, setLenis] = useStore((state) => [state.lenis, state.setLenis])

  useEffect(() => {
    window.scrollTo(0, 0)
    const lenis = new Lenis()
    window.lenis = lenis
    setLenis(lenis)

    // new ScrollSnap(lenis, { type: 'proximity' })

    return () => {
      lenis.destroy()
      setLenis(null)
    }
  }, [])

  const [hash, setHash] = useState()

  useEffect(() => {
    if (lenis && hash) {
      // scroll to on hash change
      const target = document.querySelector(hash)
      lenis.scrollTo(target, { offset: 0 })
    }
  }, [lenis, hash])

  useFrame((time) => {
    lenis?.raf(time)
  }, [])

  return (
    <>
      <div className={cn(`theme-${theme}`, s.layout, className)}>
        <Intro />
        <Cursor />
        <Scrollbar />
        <main className={s.main}>{children}</main>
        <Footer />
      </div>
    </>
  )
}
